@import url('./loguin.css');
$image-background: '../Imagenes/bg-2.jpg';
$color-prinicpal:#360371;

.pointer {
  cursor: pointer;
}
.infoText { 
    position: relative;
    display: inline-block; 
    width: auto;
  }

   //Posicion Top
   .infoText .textText-Top {
    visibility: hidden;  
    background-color: #6B6B6B;
    width: max-content;  
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 4px;  
    font-size: 10px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    margin-left: -45px;
  }
  //El Hover
  .infoText:hover .textText-Top  {
    visibility: visible;
  }

   //posicion center
   .infoText .textText-Left {
    visibility: hidden;  
    background-color: #6B6B6B;
    width: max-content;  
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 4px;  
    font-size: 10px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;    
  }
  //El Hover
  .infoText:hover .textText-Left  {
    visibility: visible;
  }
  .bg-principal {
    background-color: $color-prinicpal;
  }

  .bg-img-ox {
    background-image: url($image-background);    
    background-size:cover;
    background-attachment:unset;
    background-repeat: no-repeat;
  }
.min-width-full {
  min-width: calc(100vw - 270px);
}

.max-width-full{
  max-width: calc(100vw - 270px);
}

.main-contenedor{ 
  z-index: 1;
  min-width: calc(100vw);
  
}
.z1 {
  z-index: 1;
}
.z2{
  z-index: 2;
}
.l-logo{
  min-height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;  
  margin: 0px auto;
  
}

.l-logo-c{ 
  text-align: center;
 
}

//Responsive
@media screen and (min-width: 0px) {
  .main-contenedor{    
    margin-top: 80px;   
    max-width: 100vw;    
    max-width: calc(100vw - 270px);
  }
  .max-width-full {
    max-width: 100vw;
  }
  
}



//Iphone Normal
@media screen and (min-width: 544px) {
  .main-contenedor{    
    margin-top: 64px;  
    min-width: 100vw;  
  }
  .max-width-full {
    max-width: 100vw;
  }
  
}

//Iphone Giro
@media screen and (min-width: 768px) {
  

}

@media screen and (min-width: 992px) {
  .main-contenedor{  
    margin-top: 20px;   
    min-width: calc(100vw - 270px);
  }
  .max-width-full{
    max-width: calc(100vw - 270px);
  }

}

@media screen and (min-width: 1200px) {
  .max-width-full{
    max-width: calc(100vw - 270px);
  }
}