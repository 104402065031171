.limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
  }
  
  
  .wrap-login100 {
    width: 100%;
    background: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
  
  }
  
  /*==================================================================
  [ login more ]*/
  .login100-more {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  
  .login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
  }
  
  
  
  /*==================================================================
  [ Form ]*/
  
  .login100-form {
    width: 560px;
    min-height: 100vh;
    display: block;
    background-color: #f7f7f7;
    padding: 55px;
    display: flexbox;    
  }
  .login100-form img {
    margin-left: calc((100% - 220px)/2);
  }
  /*------------------------------------------------------------------
  [ Responsive ]*/
  
  @media (max-width: 992px) {
    .login100-form {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .login100-more {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .login100-form {
      width: 100%;
    }
  
    .login100-more {
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    .login100-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
    }
  }
  